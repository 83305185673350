import { Component, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption, RowOperation } from '@/components/os-table/os-table';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { InitiatedTaskList, InitiatedTaskQuery } from '@/resource/model';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { messageError, dateFormat, debounce, translation, messageErrors } from '@/utils';
import { mixins } from 'vue-class-component';
import { PagingMixin } from '@/mixins/paging';
import { todoTaskService } from '@/api/';
import { CustomColumnMixin } from '@/mixins/custom-column';
import { Paging, SortOptions } from '@/api/base';
import { ApproveResultEnum } from '@/resource/enum';
import { Message } from 'element-ui';

@Component({
  name: 'InitiatedTask',
  components: {}
})
export default class InitiatedTask extends mixins(PagingMixin, CustomColumnMixin) {
  public tableOption: OsTableOption<InitiatedTaskList> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 默认的表格列配置
   */
  public defaultColumnOptions: Array<OsTableColumn<InitiatedTaskList>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true
    },
    {
      prop: 'code',
      label: 'taskCenter.code',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'title',
      label: 'taskCenter.title',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'status',
      label: 'taskCenter.status',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'content',
      label: 'taskCenter.content',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    // {
    //   prop: 'approveUserName',
    //   label: 'taskCenter.approveUserName',
    //   minWidth: '100px',
    //   showOverflowTooltip: true
    // },
    {
      prop: 'createTime',
      label: 'taskCenter.createTime',
      minWidth: '100px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as InitiatedTaskList).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption<InitiatedTaskQuery>> = [
    {
      type: 'Input',
      field: 'title',
      label: 'taskCenter.title',
      option: {
        placeholder: 'taskCenter.title'
      }
    },
    {
      type: 'Select',
      field: 'status',
      label: 'taskCenter.status',
      option: {
        placeholder: 'taskCenter.status',
        clearable: false
      },
      optionData: todoTaskService.getApproveStatus()
      // .filter(x => [ApproveResultEnum.approved, ApproveResultEnum.reject].includes(x.value))
    },
    {
      type: 'DateRangePicker',
      field: 'createTime',
      label: 'taskCenter.createTime',
      option: {
        rangeSeparator: '~',
        pickerOptions: {
          disabledDate(callbackDateStr: string): boolean {
            const callbackDateTime = new Date(callbackDateStr).getTime();
            const today = new Date();
            const currentDateTime = today.getTime();
            return callbackDateTime >= currentDateTime;
          }
        }
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'common.revoke',
      operationType: 'revoke',
      permissionCode: 'task:myInitiate:withdrawn',
      disabled: true,
      handleClick: (): void => {
        this.revoke();
      }
    }
  ];

  public id: number = 0;

  public editRow: InitiatedTaskList | null = null;

  /**
   * table行的操作配置
   */
  public rowOperationOptions: RowOperation<InitiatedTaskList> = {
    fixed: 'right',
    width: '100px',
    operations: []
  };

  public selectedRows: Array<InitiatedTaskList> = [];
  public paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  private queryForm: Partial<InitiatedTaskQuery> = {
    title: '',
    status: undefined,
    createTime: [],
    createStartTime: undefined,
    createEndTime: undefined
  };

  private sortOptions: SortOptions<InitiatedTaskList> = this.tableOption.defaultSort!;

  public created(): void {
    this.initColumns(this.defaultColumnOptions, 'initiatedTask');
    this.loadData();
  }

  public activated(): void {
    this.loadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    this.clearSelection();
    this.loadData();
  }

  public handleSortChange(sortOptions: SortOptions<InitiatedTaskList>): void {
    this.sortOptions = sortOptions;
    this.reloadData();
  }

  public handleSelectionChange(selectedData: Array<InitiatedTaskList>): void {
    this.selectedRows = selectedData;
  }

  public getStatusClass(status: number): string {
    let str = '';
    switch (status) {
      case 0:
        str = 'primary-dot';
        break;
      case 1:
        str = 'success-dot';
        break;
      case 2:
        str = 'danger-dot';
        break;
      case 3:
        str = 'warning-dot';
        break;
      default:
        break;
    }
    return str;
  }
  public getStatusI18Key(status: ApproveResultEnum): string {
    if (!ApproveResultEnum[status]) {
      return 'common.unknown';
    }

    return 'approveResult.' + ApproveResultEnum[status];
  }

  public linkToDetails(rowData: InitiatedTaskList): void {
    // 打开详情 弹窗
    this.$router.push({
      path: 'task-details',
      query: {
        _id: `${rowData.id}`,
        bizKey: `${rowData.bizKey}`,
        taskId: ``,
        businessId: `${rowData.businessId}`
      }
    });
  }

  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    todoTaskService
      .getIntiatedList(this.queryForm, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total || 0;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<InitiatedTaskList>): void {
    this.operationOptions.forEach(x => {
      if (![''].includes(x.operationType)) {
        x.disabled = value.length === 0;
      }
    });
  }

  @Watch('queryForm.createTime')
  private handleCreateTimeChanged(value: Array<string>): void {
    if (!value || value.length === 0) {
      this.queryForm.createStartTime = undefined;
      this.queryForm.createEndTime = undefined;
      return;
    }
    if (value && value.length === 2) {
      this.queryForm.createStartTime = dateFormat(value[0]);
      this.queryForm.createEndTime = dateFormat(value[1], 'YYYY-MM-DD') + ' 23:59:59';
    }
  }

  private clearSelection(): void {
    (this.$refs.initiatedTaskTable as OsTable).clearSelection();
    this.selectedRows = [];
  }

  /**
   * 撤销
   */
  private async revoke(): Promise<void> {
    try {
      if (this.selectedRows.length > 1) {
        Message.warning(translation('common.selectMaxDataTip'));
        return;
      }
      const idList: Array<number> = this.selectedRows
        .filter(item => item.status === ApproveResultEnum.approving)
        .map(x => x.id!);
      if (idList.length === 0) {
        Message.error(translation('taskCenter.selectRevokeTip'));
        return;
      }
      await todoTaskService.revoke(idList[0]);
      this.reloadData();
      Message.success(translation('operationRes.operationSuccess'));
    } catch (error) {
      messageErrors(error);
    }
  }
}
